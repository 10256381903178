<template>
  <div class="about-freeze">
    <Nav paths="/freeze" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="whatis">
      <div class="en-title">What Is Dong Mian</div>
      <div class="chs-title">什么是冻眠</div>
      <p>冻眠，是一种以液体为媒介的高速冻结技术。该技术能使细胞内冰晶直径保持在5微米以下。冻眠技术具有以下特点:冻结速度快，冻结过程不破坏细胞膜和细胞壁，解冻后即可恢复细胞原有的状态、安全无毒、成本低、环保、无耗损等。冻眠冻结速度是一般空气冷冻的20倍。由于细胞冰晶小，解冻时间短，冻眠的肉类解冻后基本无血水渗出，无养分流失，无环境污染。</p>
    </div>
    <div class="founder">
      <div class="content">
        <el-row :gutter="20">
          <el-col :span="15">
            <div class="text" data-aos="fade-right" data-aos-duration="1000">
              <div class="title">冻眠技术发明人山田义夫</div>
              <div class="values">
                <p>冻眠技术(TOMIN)在20世纪90年代由日本的山田义夫社长发明。冻眠技术能使食物保质时间比普通冷冻下延长10倍以上，大大改写了T.TT理论中关于时间对冷冻食品品质影响的常规数据。在同样的冷冻效果下，冻眠技术比液氮冷冻的成本降低了70%以上。</p>
                <p>2018年5月31日，山田义夫先生代表日本TECHNICAN株式会社，作为联合国可持续发展委员会选出的就17项可持续发展目标有贡献的企业之一，在纽约联合国总部首次进行了题为《冻眠技术能改变什么》的演讲。冻眠可以解决食品浪费问题，季节性强的食材可保证整年稳定供应，产业的活性化会增加新的雇用机会等等。该演讲标志着冻眠技术对人类社会的可持续发展贡献良多。</p>
              </div>
            </div>
          </el-col>
          <el-col :span="9" class="founderimg" data-aos="fade-left" data-aos-duration="1000">
            <img :src="require('@/assets/freeze/founder1.png')" width="448" height="460">
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="8" data-aos="fade-up" data-aos-duration="1000">
            <div class="desc">
              <img :src="require('@/assets/freeze/founder2.png')" width="448" height="288">
              <p class="desc-text">山田·义夫先生和联合国官员进行会谈</p>
            </div>
          </el-col>
          <el-col :span="8" data-aos="fade-up" data-aos-duration="1500">
            <div class="desc">
              <img :src="require('@/assets/freeze/founder3.png')" width="448" height="288">
              <p class="desc-text">山田·义夫先生和联合国官员合影</p>
            </div>
          </el-col>
          <el-col :span="8" data-aos="fade-up" data-aos-duration="2000">
            <div class="desc">
              <img :src="require('@/assets/freeze/founder4.png')" width="448" height="288">
              <p class="desc-text">山田·义夫先生在联合国总部会场做演讲</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="introduction">
      <div class="content">
        <el-row :gutter="20">
          <el-col :span="9" class="introductionimg" data-aos="fade-right" data-aos-duration="1000">
            <img :src="require('@/assets/freeze/introduction1.png')" width="448" height="460">
          </el-col>
          <el-col :span="15" data-aos="fade-left" data-aos-duration="1000">
            <div class="text">
              <div class="title">技术引入</div>
              <div class="values">
                <p>2018年，泰克尼康科技有限公司将冻眠技术引进中国，开启了冻眠技术的中国化发展进程。引进技术后，泰克尼康公司实现了技术的进一步突破，取得了多项专利发明。国产冻眠机在2021年量产。冻眠技术在高端食品加下领域讯速得到应用推广。2021年推出带“冻眠市场”标志的冻眠生鲜食材和冻眠熟食品。</p>
                <p>速冻器械的优劣就在于能以多快的速度通过产生冰晶粒的温度带，冻眠技术在这方面突破了传统冷冻技术的局限。冻眠，给人们带来了更节约、更环保、更健康、更便捷的生活方式。</p>
              </div>
            </div>
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="8" data-aos="fade-down" data-aos-duration="1000">
            <div class="desc">
              <img :src="require('@/assets/freeze/introduction2.png')" width="448" height="288">
            </div>
          </el-col>
          <el-col :span="8" data-aos="fade-down" data-aos-duration="1500">
            <div class="desc">
              <img :src="require('@/assets/freeze/introduction3.png')" width="448" height="288">
            </div>
          </el-col>
          <el-col :span="8" data-aos="fade-down" data-aos-duration="2000">
            <div class="desc">
              <img :src="require('@/assets/freeze/introduction4.png')" width="448" height="288">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="prospect">
      <div class="title">
        <h3>前景展望</h3>
      </div>
      <div class="item-1">
        <el-row :gutter="20">
          <el-col :span="5" data-aos="fade-right" data-aos-duration="2000">
            <div class="sub-title">
              冻眠食品
            </div>
          </el-col>
          <el-col :span="19" data-aos="zoom-in" data-aos-duration="1000">
            <div class="sub-val">
              <p>传统的冷冻技术只着眼于冷冻功能本身，并未顾及色、香、味等食材原状。由于降温速度慢而产生的大冰晶会破坏细胞结构，从而使食材失去了原有的风味和状态。</p>
              <p>相比之下，冻眠技术可以将冰晶大小可以控制在5微米内，细胞组织被有效保护，所以食材的色泽、风味等几乎不会发生变化，解冻后即可恢复原有的新鲜度。冻眠状态下氨基酸、维生素等营养成分和美味成分能被锁住在细胞内。所以烹调后的色、香、味、口感与生鲜食物并无差异。生食宛如现採、现捞，熟食则可原味封存，无须人工添色或添加任何抗氧化剂，而且冷冻状态下的保质期比传统冷冻的延长10倍以上。</p>
            </div>
          </el-col>
        </el-row>
        <div class="ordernum" data-aos="fade-right" data-aos-duration="1000">01</div>
      </div>
      <div class="item-2">
        <el-row :gutter="20">
          <el-col :span="5" data-aos="fade-right" data-aos-duration="2000">
            <div class="sub-title">
              医用冻眠
            </div>
          </el-col>
          <el-col :span="19" data-aos="zoom-in" data-aos-duration="1000">
            <div class="sub-val">
              <p>更深入的冻眠技术还可用于医疗领域，对于医药制品和生物有机体的冻眠，冻结速度比用于食品领域的要求还要快25%，能将有机体内冰晶直径有效控制在2微米内。达到医药生物制品保鲜的要求。冻眠技术被逐步推广到医疗领域的多个方面：血液冷冻保存、病理检查、生物样本库、外科手术和同种移植、脑部疾病的应用等等。</p>
            </div>
          </el-col>
        </el-row>
        <div class="ordernum" data-aos="fade-right" data-aos-duration="1000">02</div>
      </div>
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>



<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {

    }
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.about-freeze {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 2000px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-bg {
      width: 1920px;
      height: 750px;
      background: url("../assets/freeze.png") no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  .whatis {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 50px auto;
    .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 10px;
      text-align: center;
    }
    .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
    & p {
      font-size: 22px;
      line-height: 36px;
      color: #000;
      letter-spacing: 3px;
      text-indent: 50px;
      margin-top: 40px;
    }
  }
  .founder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 80px 0;
    background-color: #f7f7f7;
    background-attachment: fixed;
    & .content {
      position: relative;
      width: 1400px;
      margin: 0 auto;
      & .text {
        display: flex;
        flex-direction: column;
        & .title {
          font-size: 36px;
          font-weight: bold;
          padding-left: 20px;
          color: #000;
          border-left: 4px solid #00a0e9;
          margin: 40px 0;
          letter-spacing: 3px;
        }
        & .values {
          & p {
            font-size: 22px;
            line-height: 30px;
            text-indent: 50px;
            letter-spacing: 3px;
          }
        }
      }
      & .founderimg {
        display: flex;
        justify-content: flex-end;
        padding-right: 15px !important;
      }
      & .desc {
        position: relative;
        overflow: hidden;
        width: 448px;
        height: 288px;
        border-radius: 10px;
        & img {
          transition: all 1s;
        }
        & img:hover {
          transform: scale(1.25);
        }
        & .desc-text {
          position: absolute;
          width: 448px;
          height: 50px;
          line-height: 50px;
          left: 0;
          bottom: 0;
          color: #fff;
          font-size: 18px;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 1),
            rgba(255, 255, 255, 0)
          );
          border-radius: 0 0 10px 10px;
          text-indent: 30px;
        }
      }
    }
  }
  .introduction {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 80px 0;
    .content {
      position: relative;
      width: 1400px;
      margin: 0 auto;
      .text {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 36px;
          font-weight: bold;
          padding-right: 20px;
          color: #000;
          border-right: 4px solid #00a0e9;
          margin: 40px 0;
          letter-spacing: 3px;
          text-align: right;
        }
        .values {
          p {
            font-size: 22px;
            line-height: 30px;
            text-indent: 50px;
            letter-spacing: 3px;
          }
        }
      }
      .introductionimg {
        display: flex;
        justify-content: flex-start;
        padding-right: 15px !important;
      }
      .desc {
        position: relative;
        overflow: hidden;
        width: 448px;
        height: 288px;
        border-radius: 10px;
        img {
          transition: all 1s;
        }
        img:hover {
          transform: scale(1.25);
        }
      }
    }
  }
  .prospect {
    position: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    & .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 167px;
      background: url("../assets/freeze/bg1.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      margin-bottom: 50px;
      & h3 {
        font-size: 36px;
        color: #fff;
        letter-spacing: 5px;
      }
    }
    .item-1 {
      position: relative;
      width: 1400px;
      margin-top: 80px;
      & .sub-title {
        width: 235px;
        background-color: #f1f1f1;
        padding: 177px 34px 52px;
        font-size: 36px;
        font-weight: bold;
        color: #000;
        text-align: center;
        border-radius: 10px;
        letter-spacing: 5px;
      }
      & .sub-val {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1045px;
        height: 320px;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 36px 42px;
        transition: all 0.5s;
        &:hover {
          border: 0;
          box-shadow: 5px 5px 5px #f1f1f1, -5px 5px 5px #f1f1f1,
            5px -5px 5px #f1f1f1, -5px -5px 5px #f1f1f1;
        }
        & p {
          font-size: 22px;
          color: #000;
          line-height: 34px;
          text-indent: 50px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        }
      }
      .ordernum {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -33px;
        left: -50px;
        width: 160px;
        height: 160px;
        font-size: 80px;
        font-weight: bold;
        color: #fff;
        background-color: #00a0e9;
        text-align: center;
        letter-spacing: 5px;
        border-radius: 10px;
      }
    }
    .item-2 {
      position: relative;
      width: 1400px;
      margin-top: 80px;
      & .sub-title {
        width: 235px;
        background-color: #f1f1f1;
        padding: 177px 34px 52px;
        font-size: 36px;
        font-weight: bold;
        color: #000;
        text-align: center;
        border-radius: 10px;
        letter-spacing: 5px;
      }
      & .sub-val {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1045px;
        height: 320px;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 36px 42px;
        transition: all 0.5s;
        &:hover {
          border: 0;
          box-shadow: 5px 5px 5px #f1f1f1, -5px 5px 5px #f1f1f1,
            5px -5px 5px #f1f1f1, -5px -5px 5px #f1f1f1;
        }
        & p {
          font-size: 22px;
          color: #000;
          line-height: 34px;
          text-indent: 50px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
        }
      }
      .ordernum {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -33px;
        left: -50px;
        width: 160px;
        height: 160px;
        font-size: 80px;
        font-weight: bold;
        color: #fff;
        background-color: #00a0e9;
        text-align: center;
        letter-spacing: 5px;
        border-radius: 10px;
      }
    }
  }
}
</style>